<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-harddrives"> </i> 固定资产 | 信息记录</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增记录</el-button>
            <el-button type="warning" @click="imp">导入新记录</el-button>
            <!-- <form id="fm_export" method="post" action="/cap/info/export" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="STA" v-model="grid.sea.STA" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态">
                <el-option label="在用" value="1" />
                <el-option label="库存" value="2" />
                <el-option label="报废" value="3" />
              </el-select>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
              <el-table-column prop="SN" label="编号" width="100" />
              <el-table-column prop="NAME" label="名称" width="200" />
              <el-table-column label="供货商" width="300">
                <template slot-scope="scope">
                  {{ scope.row.PROVIDER }} ({{ scope.row.CONTACT_MOBILE }})
                </template>
              </el-table-column>
              <el-table-column prop="POSITION" label="位置" width="200" />
              <el-table-column label="价值(元)" width="100" align="center">
                <template slot-scope="scope">
                  ￥{{ scope.row.AMOUNT }}
                </template>
              </el-table-column>
              <el-table-column label="购入日期" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.PURCHASE_DT | datetime('YYYY-MM-DD') }}
                </template>
              </el-table-column>
              <el-table-column label="保修截止" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.GUARANTEE_EXP | datetime('YYYY-MM-DD') }}
                </template>
              </el-table-column>
              <el-table-column prop="STA_TXT" label="状态" width="60" align="center" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="info_edit" @on-saved="getList" />
    </div>
    <div>
      <IMP ref="info_imp" @on-saved="filter" />
    </div>
  </div>
</template>
<script>
import Edit from "./edit";
import Wgrid from "@/components/wgrid";
import IMP from './imp'
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid, Edit, IMP },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
        },
        ls: [],
        total: 0,
        loading: false
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/CAP/InfoApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    rowEdit(r) {
      this.$refs.info_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/CAP/InfoApi/Delete",
          data: { ID: r.ID },
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
    imp() {
      this.$refs.info_imp.init();
    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('STA', this.grid.sea.STA || '')
      this.whale.remote.getExport({
        url: "/api/School/CAP/INFO/Export",
        data: formData,
        completed: function (its) {
          console.log('exls', its)
          jsFileDownload(its,'导出固定资产信息记录.xls')
          // self.whale.downLoad(its.Data)
          self.grid.loading = false;
        }
      })
    },
  }
}
</script>

