<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">增加/修改 记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>编号</b></label>
            <el-input type="text" v-model="model.SN" />
          </div>
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>提供商</b></label>
            <el-input type="text" v-model="model.PROVIDER" />
          </div>
          <div class="form-group">
            <label><b>联系电话</b></label>
            <el-input type="text" v-model="model.CONTACT_MOBILE" />
          </div>
          <div class="form-group">
            <label><b>存放位置</b></label>
            <el-input type="text" v-model="model.POSITION" />
          </div>
          <div class="form-group">
            <label><b>价值</b></label>
            <div>
              <el-input-number v-model="model.AMOUNT" :step="1" :min="0"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>购入日期</b></label>
            <div>
              <el-date-picker v-model="model.PURCHASE_DT" type="date" placeholder="选择日期"/>
            </div>
          </div>
          <div class="form-group">
            <label><b>保修截止日期</b></label>
            <div>
              <el-date-picker v-model="model.GUARANTEE_EXP" type="date" placeholder="选择日期"/>
            </div>
          </div>
          <div class="form-group">
            <label><b>状态</b></label><br/>
            <div>
              <el-select v-model="model.STA" placeholder="请选择状态">
                <el-option label="在用" :value=1 />
                <el-option label="库存" :value=2 />
                <el-option label="报废" :value=3 />
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {},
    }
  },
  methods:{
    init(model){
      this.sta={show:true,loading:false}
      if (model==undefined || model==null) {
        this.model = {STA:1,AMOUNT:0};
      } else {
        this.model=JSON.parse((JSON.stringify(model)));
      }
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/CAP/InfoApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
